export const cityjson = [{
    "code": "A",
    "citylist": [{
        "id": 513200,
        "areaname": "\u963F\u575D\u85CF\u65CF\u7F8C\u65CF\u81EA\u6CBB\u5DDE",
        "center": "102.221374,31.899792"
    }, {
        "id": 652900,
        "areaname": "\u963F\u514B\u82CF\u5730\u533A",
        "center": "80.265068,41.170712"
    }, {
        "id": 152900,
        "areaname": "\u963F\u62C9\u5584\u76DF",
        "center": "105.706422,38.844814"
    }, {
        "id": 654300,
        "areaname": "\u963F\u52D2\u6CF0\u5730\u533A",
        "center": "88.13963,47.848393"
    }, {
        "id": 542500,
        "areaname": "\u963F\u91CC\u5730\u533A",
        "center": "80.105498,32.503187"
    }, {
        "id": 610900,
        "areaname": "\u5B89\u5EB7\u5E02",
        "center": "109.029273,32.6903"
    }, {
        "id": 340800,
        "areaname": "\u5B89\u5E86\u5E02",
        "center": "117.043551,30.50883"
    }, {
        "id": 520400,
        "areaname": "\u5B89\u987A\u5E02",
        "center": "105.932188,26.245544"
    }, {
        "id": 410500,
        "areaname": "\u5B89\u9633\u5E02",
        "center": "114.352482,36.103442"
    }, {
        "id": 210300,
        "areaname": "\u978D\u5C71\u5E02",
        "center": "122.995632,41.110626"
    }, {
        "id": 820100,
        "areaname": "\u6FB3\u95E8\u534A\u5C9B",
        "center": ""
    }, {
        "id": 820200,
        "areaname": "\u6FB3\u95E8\u79BB\u5C9B",
        "center": ""
    }]
}, {
    "code": "B",
    "citylist": [{
        "id": 150800,
        "areaname": "\u5DF4\u5F66\u6DD6\u5C14\u5E02",
        "center": "107.416959,40.757402"
    }, {
        "id": 652800,
        "areaname": "\u5DF4\u97F3\u90ED\u695E\u8499\u53E4\u81EA\u6CBB\u5DDE",
        "center": "86.150969,41.768552"
    }, {
        "id": 511900,
        "areaname": "\u5DF4\u4E2D\u5E02",
        "center": "106.753669,31.858809"
    }, {
        "id": 220800,
        "areaname": "\u767D\u57CE\u5E02",
        "center": "122.841114,45.619026"
    }, {
        "id": 220600,
        "areaname": "\u767D\u5C71\u5E02",
        "center": "126.427839,41.942505"
    }, {
        "id": 620400,
        "areaname": "\u767D\u94F6\u5E02",
        "center": "104.173606,36.54568"
    }, {
        "id": 451000,
        "areaname": "\u767E\u8272\u5E02",
        "center": "106.616285,23.897742"
    }, {
        "id": 340300,
        "areaname": "\u868C\u57E0\u5E02",
        "center": "117.363228,32.939667"
    }, {
        "id": 150200,
        "areaname": "\u5305\u5934\u5E02",
        "center": "109.840405,40.658168"
    }, {
        "id": 610300,
        "areaname": "\u5B9D\u9E21\u5E02",
        "center": "107.14487,34.369315"
    }, {
        "id": 130600,
        "areaname": "\u4FDD\u5B9A\u5E02",
        "center": "115.482331,38.867657"
    }, {
        "id": 530500,
        "areaname": "\u4FDD\u5C71\u5E02",
        "center": "99.167133,25.111802"
    }, {
        "id": 450500,
        "areaname": "\u5317\u6D77\u5E02",
        "center": "109.119254,21.473343"
    }, {
        "id": 110100,
        "areaname": "\u5317\u4EAC\u5E02",
        "center": "116.405285,39.904989"
    }, {
        "id": 210500,
        "areaname": "\u672C\u6EAA\u5E02",
        "center": "123.770519,41.297909"
    }, {
        "id": 520500,
        "areaname": "\u6BD5\u8282\u5E02",
        "center": "105.28501,27.301693"
    }, {
        "id": 371600,
        "areaname": "\u6EE8\u5DDE\u5E02",
        "center": "118.016974,37.383542"
    }, {
        "id": 652700,
        "areaname": "\u535A\u5C14\u5854\u62C9\u8499\u53E4\u81EA\u6CBB\u5DDE",
        "center": "82.074778,44.903258"
    }]
}, {
    "code": "C",
    "citylist": [{
        "id": 130900,
        "areaname": "\u6CA7\u5DDE\u5E02",
        "center": "116.857461,38.310582"
    }, {
        "id": 542100,
        "areaname": "\u660C\u90FD\u5730\u533A",
        "center": "97.18131,31.140161"
    }, {
        "id": 652300,
        "areaname": "\u660C\u5409\u56DE\u65CF\u81EA\u6CBB\u5DDE",
        "center": "87.304012,44.014577"
    }, {
        "id": 220100,
        "areaname": "\u957F\u6625\u5E02",
        "center": "125.3245,43.886841"
    }, {
        "id": 430100,
        "areaname": "\u957F\u6C99\u5E02",
        "center": "112.982279,28.19409"
    }, {
        "id": 140400,
        "areaname": "\u957F\u6CBB\u5E02",
        "center": "113.113556,36.191112"
    }, {
        "id": 430700,
        "areaname": "\u5E38\u5FB7\u5E02",
        "center": "111.691347,29.040225"
    }, {
        "id": 320400,
        "areaname": "\u5E38\u5DDE\u5E02",
        "center": "119.946973,31.772752"
    }, {
        "id": 211300,
        "areaname": "\u671D\u9633\u5E02",
        "center": "120.451176,41.576758"
    }, {
        "id": 445100,
        "areaname": "\u6F6E\u5DDE\u5E02",
        "center": "116.632301,23.661701"
    }, {
        "id": 431000,
        "areaname": "\u90F4\u5DDE\u5E02",
        "center": "113.032067,25.793589"
    }, {
        "id": 510100,
        "areaname": "\u6210\u90FD\u5E02",
        "center": "104.065735,30.659462"
    }, {
        "id": 130800,
        "areaname": "\u627F\u5FB7\u5E02",
        "center": "117.939152,40.976204"
    }, {
        "id": 341700,
        "areaname": "\u6C60\u5DDE\u5E02",
        "center": "117.489157,30.656037"
    }, {
        "id": 150400,
        "areaname": "\u8D64\u5CF0\u5E02",
        "center": "118.956806,42.275317"
    }, {
        "id": 451400,
        "areaname": "\u5D07\u5DE6\u5E02",
        "center": "107.353926,22.404108"
    }, {
        "id": 341100,
        "areaname": "\u6EC1\u5DDE\u5E02",
        "center": "118.316264,32.303627"
    }, {
        "id": 532300,
        "areaname": "\u695A\u96C4\u5F5D\u65CF\u81EA\u6CBB\u5DDE",
        "center": "101.546046,25.041988"
    }]
}, {
    "code": "D",
    "citylist": [{
        "id": 511700,
        "areaname": "\u8FBE\u5DDE\u5E02",
        "center": "107.502262,31.209484"
    }, {
        "id": 532900,
        "areaname": "\u5927\u7406\u767D\u65CF\u81EA\u6CBB\u5DDE",
        "center": "100.225668,25.589449"
    }, {
        "id": 210200,
        "areaname": "\u5927\u8FDE\u5E02",
        "center": "121.618622,38.91459"
    }, {
        "id": 230600,
        "areaname": "\u5927\u5E86\u5E02",
        "center": "125.11272,46.590734"
    }, {
        "id": 140200,
        "areaname": "\u5927\u540C\u5E02",
        "center": "113.295259,40.09031"
    }, {
        "id": 232700,
        "areaname": "\u5927\u5174\u5B89\u5CAD\u5730\u533A",
        "center": "124.711526,52.335262"
    }, {
        "id": 210600,
        "areaname": "\u4E39\u4E1C\u5E02",
        "center": "124.383044,40.124296"
    }, {
        "id": 533100,
        "areaname": "\u5FB7\u5B8F\u50A3\u65CF\u666F\u9887\u65CF\u81EA\u6CBB\u5DDE",
        "center": "98.578363,24.436694"
    }, {
        "id": 510600,
        "areaname": "\u5FB7\u9633\u5E02",
        "center": "104.398651,31.127991"
    }, {
        "id": 371400,
        "areaname": "\u5FB7\u5DDE\u5E02",
        "center": "116.307428,37.453968"
    }, {
        "id": 533400,
        "areaname": "\u8FEA\u5E86\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "99.706463,27.826853"
    }, {
        "id": 621100,
        "areaname": "\u5B9A\u897F\u5E02",
        "center": "104.626294,35.579578"
    }, {
        "id": 441900,
        "areaname": "\u4E1C\u839E\u5E02",
        "center": "113.846,23.1024"
    }, {
        "id": 370500,
        "areaname": "\u4E1C\u8425\u5E02",
        "center": "118.66471,37.434564"
    }]
}, {
    "code": "E",
    "citylist": [{
        "id": 150600,
        "areaname": "\u9102\u5C14\u591A\u65AF\u5E02",
        "center": "109.99029,39.817179"
    }, {
        "id": 420700,
        "areaname": "\u9102\u5DDE\u5E02",
        "center": "114.890593,30.396536"
    }, {
        "id": 422800,
        "areaname": "\u6069\u65BD\u571F\u5BB6\u65CF\u82D7\u65CF\u81EA\u6CBB\u5DDE",
        "center": "109.48699,30.283114"
    }]
}, {
    "code": "F",
    "citylist": [{
        "id": 450600,
        "areaname": "\u9632\u57CE\u6E2F\u5E02",
        "center": "108.345478,21.614631"
    }, {
        "id": 440600,
        "areaname": "\u4F5B\u5C71\u5E02",
        "center": "113.122717,23.028762"
    }, {
        "id": 350100,
        "areaname": "\u798F\u5DDE\u5E02",
        "center": "119.306239,26.075302"
    }, {
        "id": 210400,
        "areaname": "\u629A\u987A\u5E02",
        "center": "123.921109,41.875956"
    }, {
        "id": 361000,
        "areaname": "\u629A\u5DDE\u5E02",
        "center": "116.358351,27.98385"
    }, {
        "id": 210900,
        "areaname": "\u961C\u65B0\u5E02",
        "center": "121.648962,42.011796"
    }, {
        "id": 341200,
        "areaname": "\u961C\u9633\u5E02",
        "center": "115.819729,32.896969"
    }]
}, {
    "code": "G",
    "citylist": [{
        "id": 623000,
        "areaname": "\u7518\u5357\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "102.911008,34.986354"
    }, {
        "id": 513300,
        "areaname": "\u7518\u5B5C\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "101.963815,30.050663"
    }, {
        "id": 360700,
        "areaname": "\u8D63\u5DDE\u5E02",
        "center": "114.940278,25.85097"
    }, {
        "id": 710200,
        "areaname": "\u9AD8\u96C4\u5E02",
        "center": ""
    }, {
        "id": 640400,
        "areaname": "\u56FA\u539F\u5E02",
        "center": "106.285241,36.004561"
    }, {
        "id": 511600,
        "areaname": "\u5E7F\u5B89\u5E02",
        "center": "106.633369,30.456398"
    }, {
        "id": 510800,
        "areaname": "\u5E7F\u5143\u5E02",
        "center": "105.829757,32.433668"
    }, {
        "id": 440100,
        "areaname": "\u5E7F\u5DDE\u5E02",
        "center": "113.280637,23.125178"
    }, {
        "id": 450800,
        "areaname": "\u8D35\u6E2F\u5E02",
        "center": "109.602146,23.0936"
    }, {
        "id": 520100,
        "areaname": "\u8D35\u9633\u5E02",
        "center": "106.713478,26.578343"
    }, {
        "id": 450300,
        "areaname": "\u6842\u6797\u5E02",
        "center": "110.299121,25.274215"
    }, {
        "id": 632600,
        "areaname": "\u679C\u6D1B\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "100.242143,34.4736"
    }, {
        "id": 230300,
        "areaname": "\u9E21\u897F\u5E02",
        "center": "130.975966,45.300046"
    }, {
        "id": 710300,
        "areaname": "\u57FA\u9686\u5E02",
        "center": ""
    }, {
        "id": 360800,
        "areaname": "\u5409\u5B89\u5E02",
        "center": "114.986373,27.111699"
    }, {
        "id": 220200,
        "areaname": "\u5409\u6797\u5E02",
        "center": "126.55302,43.843577"
    }, {
        "id": 370100,
        "areaname": "\u6D4E\u5357\u5E02",
        "center": "117.000923,36.675807"
    }, {
        "id": 370800,
        "areaname": "\u6D4E\u5B81\u5E02",
        "center": "116.587245,35.415393"
    }, {
        "id": 419001,
        "areaname": "\u6D4E\u6E90\u5E02",
        "center": "112.509,35.0243"
    }, {
        "id": 230800,
        "areaname": "\u4F73\u6728\u65AF\u5E02",
        "center": "130.361634,46.809606"
    }, {
        "id": 330400,
        "areaname": "\u5609\u5174\u5E02",
        "center": "120.750865,30.762653"
    }, {
        "id": 710700,
        "areaname": "\u5609\u4E49\u5E02",
        "center": ""
    }, {
        "id": 620200,
        "areaname": "\u5609\u5CEA\u5173\u5E02",
        "center": "98.255,39.8552"
    }, {
        "id": 440700,
        "areaname": "\u6C5F\u95E8\u5E02",
        "center": "113.094942,22.590431"
    }, {
        "id": 410800,
        "areaname": "\u7126\u4F5C\u5E02",
        "center": "113.238266,35.23904"
    }, {
        "id": 445200,
        "areaname": "\u63ED\u9633\u5E02",
        "center": "116.355733,23.543778"
    }, {
        "id": 620300,
        "areaname": "\u91D1\u660C\u5E02",
        "center": "102.187888,38.514238"
    }, {
        "id": 330700,
        "areaname": "\u91D1\u534E\u5E02",
        "center": "119.649506,29.089524"
    }, {
        "id": 210700,
        "areaname": "\u9526\u5DDE\u5E02",
        "center": "121.135742,41.119269"
    }, {
        "id": 140500,
        "areaname": "\u664B\u57CE\u5E02",
        "center": "112.851274,35.497553"
    }, {
        "id": 140700,
        "areaname": "\u664B\u4E2D\u5E02",
        "center": "112.736465,37.696495"
    }, {
        "id": 420800,
        "areaname": "\u8346\u95E8\u5E02",
        "center": "112.204251,31.03542"
    }, {
        "id": 421000,
        "areaname": "\u8346\u5DDE\u5E02",
        "center": "112.23813,30.326857"
    }, {
        "id": 360200,
        "areaname": "\u666F\u5FB7\u9547\u5E02",
        "center": "117.214664,29.29256"
    }, {
        "id": 360400,
        "areaname": "\u4E5D\u6C5F\u5E02",
        "center": "115.992811,29.712034"
    }, {
        "id": 810200,
        "areaname": "\u4E5D\u9F99",
        "center": ""
    }, {
        "id": 620900,
        "areaname": "\u9152\u6CC9\u5E02",
        "center": "98.510795,39.744023"
    }]
}, {
    "code": "H",
    "citylist": [{
        "id": 230100,
        "areaname": "\u54C8\u5C14\u6EE8\u5E02",
        "center": "126.642464,45.756967"
    }, {
        "id": 652200,
        "areaname": "\u54C8\u5BC6\u5730\u533A",
        "center": "93.491679,42.918549"
    }, {
        "id": 632200,
        "areaname": "\u6D77\u5317\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "100.901059,36.959435"
    }, {
        "id": 632100,
        "areaname": "\u6D77\u4E1C\u5730\u533A",
        "center": "102.106684,36.496876"
    }, {
        "id": 460100,
        "areaname": "\u6D77\u53E3\u5E02",
        "center": "110.33119,20.031971"
    }, {
        "id": 632500,
        "areaname": "\u6D77\u5357\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "100.619542,36.280353"
    }, {
        "id": 632800,
        "areaname": "\u6D77\u897F\u8499\u53E4\u65CF\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "97.370785,37.374663"
    }, {
        "id": 130400,
        "areaname": "\u90AF\u90F8\u5E02",
        "center": "114.490686,36.612273"
    }, {
        "id": 610700,
        "areaname": "\u6C49\u4E2D\u5E02",
        "center": "107.028621,33.077668"
    }, {
        "id": 330100,
        "areaname": "\u676D\u5DDE\u5E02",
        "center": "120.153576,30.287459"
    }, {
        "id": 340100,
        "areaname": "\u5408\u80A5\u5E02",
        "center": "117.283042,31.86119"
    }, {
        "id": 653200,
        "areaname": "\u548C\u7530\u5730\u533A",
        "center": "79.92533,37.110687"
    }, {
        "id": 451200,
        "areaname": "\u6CB3\u6C60\u5E02",
        "center": "108.062105,24.695899"
    }, {
        "id": 441600,
        "areaname": "\u6CB3\u6E90\u5E02",
        "center": "114.697802,23.746266"
    }, {
        "id": 371700,
        "areaname": "\u83CF\u6CFD\u5E02",
        "center": "115.469381,35.246531"
    }, {
        "id": 451100,
        "areaname": "\u8D3A\u5DDE\u5E02",
        "center": "111.552056,24.414141"
    }, {
        "id": 410600,
        "areaname": "\u9E64\u58C1\u5E02",
        "center": "114.295444,35.748236"
    }, {
        "id": 230400,
        "areaname": "\u9E64\u5C97\u5E02",
        "center": "130.277487,47.332085"
    }, {
        "id": 231100,
        "areaname": "\u9ED1\u6CB3\u5E02",
        "center": "127.499023,50.249585"
    }, {
        "id": 131100,
        "areaname": "\u8861\u6C34\u5E02",
        "center": "115.665993,37.735097"
    }, {
        "id": 430400,
        "areaname": "\u8861\u9633\u5E02",
        "center": "112.607693,26.900358"
    }, {
        "id": 532500,
        "areaname": "\u7EA2\u6CB3\u54C8\u5C3C\u65CF\u5F5D\u65CF\u81EA\u6CBB\u5DDE",
        "center": "103.384182,23.366775"
    }, {
        "id": 150100,
        "areaname": "\u547C\u548C\u6D69\u7279\u5E02",
        "center": "111.670801,40.818311"
    }, {
        "id": 150700,
        "areaname": "\u547C\u4F26\u8D1D\u5C14\u5E02",
        "center": "119.758168,49.215333"
    }, {
        "id": 330500,
        "areaname": "\u6E56\u5DDE\u5E02",
        "center": "120.102398,30.867198"
    }, {
        "id": 211400,
        "areaname": "\u846B\u82A6\u5C9B\u5E02",
        "center": "120.856394,40.755572"
    }, {
        "id": 431200,
        "areaname": "\u6000\u5316\u5E02",
        "center": "109.97824,27.550082"
    }, {
        "id": 320800,
        "areaname": "\u6DEE\u5B89\u5E02",
        "center": "119.021265,33.597506"
    }, {
        "id": 340600,
        "areaname": "\u6DEE\u5317\u5E02",
        "center": "116.794664,33.971707"
    }, {
        "id": 340400,
        "areaname": "\u6DEE\u5357\u5E02",
        "center": "117.018329,32.647574"
    }, {
        "id": 421100,
        "areaname": "\u9EC4\u5188\u5E02",
        "center": "114.879365,30.447711"
    }, {
        "id": 632300,
        "areaname": "\u9EC4\u5357\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "102.019988,35.517744"
    }, {
        "id": 341000,
        "areaname": "\u9EC4\u5C71\u5E02",
        "center": "118.317325,29.709239"
    }, {
        "id": 420200,
        "areaname": "\u9EC4\u77F3\u5E02",
        "center": "115.077048,30.220074"
    }, {
        "id": 441300,
        "areaname": "\u60E0\u5DDE\u5E02",
        "center": "114.412599,23.079404"
    }]
}, {
    "code": "I",
    "citylist": []
}, {
    "code": "J",
    "citylist": []
}, {
    "code": "K",
    "citylist": [{
        "id": 653100,
        "areaname": "\u5580\u4EC0\u5730\u533A",
        "center": "75.989138,39.467664"
    }, {
        "id": 410200,
        "areaname": "\u5F00\u5C01\u5E02",
        "center": "114.341447,34.797049"
    }, {
        "id": 650200,
        "areaname": "\u514B\u62C9\u739B\u4F9D\u5E02",
        "center": "84.873946,45.595886"
    }, {
        "id": 653000,
        "areaname": "\u514B\u5B5C\u52D2\u82CF\u67EF\u5C14\u514B\u5B5C\u81EA\u6CBB\u5DDE",
        "center": "76.172825,39.713431"
    }, {
        "id": 530100,
        "areaname": "\u6606\u660E\u5E02",
        "center": "102.712251,25.040609"
    }]
}, {
    "code": "L",
    "citylist": [{
        "id": 540100,
        "areaname": "\u62C9\u8428\u5E02",
        "center": "91.132212,29.660361"
    }, {
        "id": 451300,
        "areaname": "\u6765\u5BBE\u5E02",
        "center": "109.229772,23.733766"
    }, {
        "id": 371200,
        "areaname": "\u83B1\u829C\u5E02",
        "center": "117.677736,36.214397"
    }, {
        "id": 620100,
        "areaname": "\u5170\u5DDE\u5E02",
        "center": "103.823557,36.058039"
    }, {
        "id": 131000,
        "areaname": "\u5ECA\u574A\u5E02",
        "center": "116.704441,39.523927"
    }, {
        "id": 511100,
        "areaname": "\u4E50\u5C71\u5E02",
        "center": "103.761263,29.582024"
    }, {
        "id": 530700,
        "areaname": "\u4E3D\u6C5F\u5E02",
        "center": "100.233026,26.872108"
    }, {
        "id": 331100,
        "areaname": "\u4E3D\u6C34\u5E02",
        "center": "119.921786,28.451993"
    }, {
        "id": 320700,
        "areaname": "\u8FDE\u4E91\u6E2F\u5E02",
        "center": "119.178821,34.600018"
    }, {
        "id": 513400,
        "areaname": "\u51C9\u5C71\u5F5D\u65CF\u81EA\u6CBB\u5DDE",
        "center": "102.258746,27.886762"
    }, {
        "id": 211000,
        "areaname": "\u8FBD\u9633\u5E02",
        "center": "123.18152,41.269402"
    }, {
        "id": 220400,
        "areaname": "\u8FBD\u6E90\u5E02",
        "center": "125.145349,42.902692"
    }, {
        "id": 371500,
        "areaname": "\u804A\u57CE\u5E02",
        "center": "115.980367,36.456013"
    }, {
        "id": 542600,
        "areaname": "\u6797\u829D\u5730\u533A",
        "center": "94.36149,29.649128"
    }, {
        "id": 530900,
        "areaname": "\u4E34\u6CA7\u5E02",
        "center": "100.08697,23.886567"
    }, {
        "id": 141000,
        "areaname": "\u4E34\u6C7E\u5E02",
        "center": "111.517973,36.08415"
    }, {
        "id": 622900,
        "areaname": "\u4E34\u590F\u56DE\u65CF\u81EA\u6CBB\u5DDE",
        "center": "103.212006,35.599446"
    }, {
        "id": 371300,
        "areaname": "\u4E34\u6C82\u5E02",
        "center": "118.326443,35.065282"
    }, {
        "id": 450200,
        "areaname": "\u67F3\u5DDE\u5E02",
        "center": "109.411703,24.314617"
    }, {
        "id": 341500,
        "areaname": "\u516D\u5B89\u5E02",
        "center": "116.507676,31.752889"
    }, {
        "id": 520200,
        "areaname": "\u516D\u76D8\u6C34\u5E02",
        "center": "104.846743,26.584643"
    }, {
        "id": 350800,
        "areaname": "\u9F99\u5CA9\u5E02",
        "center": "117.02978,25.091603"
    }, {
        "id": 621200,
        "areaname": "\u9647\u5357\u5E02",
        "center": "104.929379,33.388598"
    }, {
        "id": 431300,
        "areaname": "\u5A04\u5E95\u5E02",
        "center": "112.008497,27.728136"
    }, {
        "id": 141100,
        "areaname": "\u5415\u6881\u5E02",
        "center": "111.134335,37.524366"
    }, {
        "id": 410300,
        "areaname": "\u6D1B\u9633\u5E02",
        "center": "112.434468,34.663041"
    }]
}, {
    "code": "M",
    "citylist": [{
        "id": 340500,
        "areaname": "\u9A6C\u978D\u5C71\u5E02",
        "center": "118.507906,31.689362"
    }, {
        "id": 440900,
        "areaname": "\u8302\u540D\u5E02",
        "center": "110.919229,21.659751"
    }, {
        "id": 511400,
        "areaname": "\u7709\u5C71\u5E02",
        "center": "103.831788,30.048318"
    }, {
        "id": 441400,
        "areaname": "\u6885\u5DDE\u5E02",
        "center": "116.117582,24.299112"
    }, {
        "id": 510700,
        "areaname": "\u7EF5\u9633\u5E02",
        "center": "104.741722,31.46402"
    }, {
        "id": 231000,
        "areaname": "\u7261\u4E39\u6C5F\u5E02",
        "center": "129.618602,44.582962"
    }]
}, {
    "code": "N",
    "citylist": [{
        "id": 511000,
        "areaname": "\u5185\u6C5F\u5E02",
        "center": "105.066138,29.58708"
    }, {
        "id": 542400,
        "areaname": "\u90A3\u66F2\u5730\u533A",
        "center": "92.051746,31.478148"
    }, {
        "id": 360100,
        "areaname": "\u5357\u660C\u5E02",
        "center": "115.892151,28.676493"
    }, {
        "id": 511300,
        "areaname": "\u5357\u5145\u5E02",
        "center": "106.082974,30.795281"
    }, {
        "id": 320100,
        "areaname": "\u5357\u4EAC\u5E02",
        "center": "118.767413,32.041544"
    }, {
        "id": 450100,
        "areaname": "\u5357\u5B81\u5E02",
        "center": "108.320004,22.82402"
    }, {
        "id": 350700,
        "areaname": "\u5357\u5E73\u5E02",
        "center": "118.178459,26.635627"
    }, {
        "id": 320600,
        "areaname": "\u5357\u901A\u5E02",
        "center": "120.864608,32.016212"
    }, {
        "id": 411300,
        "areaname": "\u5357\u9633\u5E02",
        "center": "112.540918,32.999082"
    }, {
        "id": 330200,
        "areaname": "\u5B81\u6CE2\u5E02",
        "center": "121.549792,29.868388"
    }, {
        "id": 350900,
        "areaname": "\u5B81\u5FB7\u5E02",
        "center": "119.527082,26.65924"
    }, {
        "id": 533300,
        "areaname": "\u6012\u6C5F\u5088\u50F3\u65CF\u81EA\u6CBB\u5DDE",
        "center": "98.854304,25.850949"
    }]
}, {
    "code": "O",
    "citylist": []
}, {
    "code": "P",
    "citylist": [{
        "id": 510400,
        "areaname": "\u6500\u679D\u82B1\u5E02",
        "center": "101.716007,26.580446"
    }, {
        "id": 211100,
        "areaname": "\u76D8\u9526\u5E02",
        "center": "122.06957,41.124484"
    }, {
        "id": 410400,
        "areaname": "\u5E73\u9876\u5C71\u5E02",
        "center": "113.307718,33.735241"
    }, {
        "id": 620800,
        "areaname": "\u5E73\u51C9\u5E02",
        "center": "106.684691,35.54279"
    }, {
        "id": 360300,
        "areaname": "\u840D\u4E61\u5E02",
        "center": "113.852186,27.622946"
    }, {
        "id": 350300,
        "areaname": "\u8386\u7530\u5E02",
        "center": "119.007558,25.431011"
    }, {
        "id": 530800,
        "areaname": "\u666E\u6D31\u5E02",
        "center": "100.972344,22.777321"
    }]
}, {
    "code": "Q",
    "citylist": [{
        "id": 230900,
        "areaname": "\u4E03\u53F0\u6CB3\u5E02",
        "center": "131.015584,45.771266"
    }, {
        "id": 230200,
        "areaname": "\u9F50\u9F50\u54C8\u5C14\u5E02",
        "center": "123.95792,47.342081"
    }, {
        "id": 522600,
        "areaname": "\u9ED4\u4E1C\u5357\u82D7\u65CF\u4F97\u65CF\u81EA\u6CBB\u5DDE",
        "center": "107.977488,26.583352"
    }, {
        "id": 522700,
        "areaname": "\u9ED4\u5357\u5E03\u4F9D\u65CF\u82D7\u65CF\u81EA\u6CBB\u5DDE",
        "center": "107.517156,26.258219"
    }, {
        "id": 522300,
        "areaname": "\u9ED4\u897F\u5357\u5E03\u4F9D\u65CF\u82D7\u65CF\u81EA\u6CBB\u5DDE",
        "center": "104.897971,25.08812"
    }, {
        "id": 450700,
        "areaname": "\u94A6\u5DDE\u5E02",
        "center": "108.624175,21.967127"
    }, {
        "id": 130300,
        "areaname": "\u79E6\u7687\u5C9B\u5E02",
        "center": "119.586579,39.942531"
    }, {
        "id": 370200,
        "areaname": "\u9752\u5C9B\u5E02",
        "center": "120.355173,36.082982"
    }, {
        "id": 441800,
        "areaname": "\u6E05\u8FDC\u5E02",
        "center": "113.051227,23.685022"
    }, {
        "id": 621000,
        "areaname": "\u5E86\u9633\u5E02",
        "center": "107.638372,35.734218"
    }, {
        "id": 530300,
        "areaname": "\u66F2\u9756\u5E02",
        "center": "103.797851,25.501557"
    }, {
        "id": 350500,
        "areaname": "\u6CC9\u5DDE\u5E02",
        "center": "118.589421,24.908853"
    }]
}, {
    "code": "R",
    "citylist": [{
        "id": 542300,
        "areaname": "\u65E5\u5580\u5219\u5730\u533A",
        "center": "88.885817,29.26681"
    }, {
        "id": 371100,
        "areaname": "\u65E5\u7167\u5E02",
        "center": "119.461208,35.428588"
    }]
}, {
    "code": "S",
    "citylist": [{
        "id": 411200,
        "areaname": "\u4E09\u95E8\u5CE1\u5E02",
        "center": "111.194099,34.777338"
    }, {
        "id": 350400,
        "areaname": "\u4E09\u660E\u5E02",
        "center": "117.635001,26.265444"
    }, {
        "id": 460300,
        "areaname": "\u4E09\u6C99\u5E02",
        "center": "112.34882,16.831039"
    }, {
        "id": 460200,
        "areaname": "\u4E09\u4E9A\u5E02",
        "center": "109.508268,18.247872"
    }, {
        "id": 542200,
        "areaname": "\u5C71\u5357\u5730\u533A",
        "center": "91.773134,29.237137"
    }, {
        "id": 440500,
        "areaname": "\u6C55\u5934\u5E02",
        "center": "116.708463,23.37102"
    }, {
        "id": 441500,
        "areaname": "\u6C55\u5C3E\u5E02",
        "center": "115.364238,22.774485"
    }, {
        "id": 611000,
        "areaname": "\u5546\u6D1B\u5E02",
        "center": "109.939776,33.868319"
    }, {
        "id": 411400,
        "areaname": "\u5546\u4E18\u5E02",
        "center": "115.650497,34.437054"
    }, {
        "id": 310100,
        "areaname": "\u4E0A\u6D77\u5E02",
        "center": "121.472644,31.231706"
    }, {
        "id": 361100,
        "areaname": "\u4E0A\u9976\u5E02",
        "center": "117.971185,28.44442"
    }, {
        "id": 440200,
        "areaname": "\u97F6\u5173\u5E02",
        "center": "113.591544,24.801322"
    }, {
        "id": 430500,
        "areaname": "\u90B5\u9633\u5E02",
        "center": "111.46923,27.237842"
    }, {
        "id": 330600,
        "areaname": "\u7ECD\u5174\u5E02",
        "center": "120.582112,29.997117"
    }, {
        "id": 440300,
        "areaname": "\u6DF1\u5733\u5E02",
        "center": "114.085947,22.547"
    }, {
        "id": 210100,
        "areaname": "\u6C88\u9633\u5E02",
        "center": "123.429096,41.796767"
    }, {
        "id": 719000,
        "areaname": "\u7701\u76F4\u8F96",
        "center": ""
    }, {
        "id": 469000,
        "areaname": "\u7701\u76F4\u8F96",
        "center": "110.33119,20.031971"
    }, {
        "id": 429000,
        "areaname": "\u7701\u76F4\u8F96",
        "center": "114.351688,30.546452"
    }, {
        "id": 420300,
        "areaname": "\u5341\u5830\u5E02",
        "center": "110.787916,32.646907"
    }, {
        "id": 130100,
        "areaname": "\u77F3\u5BB6\u5E84\u5E02",
        "center": "114.502461,38.045474"
    }, {
        "id": 640200,
        "areaname": "\u77F3\u5634\u5C71\u5E02",
        "center": "106.376173,39.01333"
    }, {
        "id": 230500,
        "areaname": "\u53CC\u9E2D\u5C71\u5E02",
        "center": "131.157304,46.643442"
    }, {
        "id": 140600,
        "areaname": "\u6714\u5DDE\u5E02",
        "center": "112.433387,39.331261"
    }, {
        "id": 220300,
        "areaname": "\u56DB\u5E73\u5E02",
        "center": "124.370785,43.170344"
    }, {
        "id": 220700,
        "areaname": "\u677E\u539F\u5E02",
        "center": "124.823608,45.118243"
    }, {
        "id": 320500,
        "areaname": "\u82CF\u5DDE\u5E02",
        "center": "120.619585,31.299379"
    }, {
        "id": 321300,
        "areaname": "\u5BBF\u8FC1\u5E02",
        "center": "118.275162,33.963008"
    }, {
        "id": 341300,
        "areaname": "\u5BBF\u5DDE\u5E02",
        "center": "116.984084,33.633891"
    }, {
        "id": 231200,
        "areaname": "\u7EE5\u5316\u5E02",
        "center": "126.99293,46.637393"
    }, {
        "id": 421300,
        "areaname": "\u968F\u5DDE\u5E02",
        "center": "113.37377,31.717497"
    }, {
        "id": 510900,
        "areaname": "\u9042\u5B81\u5E02",
        "center": "105.571331,30.513311"
    }]
}, {
    "code": "T",
    "citylist": [{
        "id": 654200,
        "areaname": "\u5854\u57CE\u5730\u533A",
        "center": "82.985732,46.746301"
    }, {
        "id": 710100,
        "areaname": "\u53F0\u5317\u5E02",
        "center": ""
    }, {
        "id": 710500,
        "areaname": "\u53F0\u5357\u5E02",
        "center": ""
    }, {
        "id": 710400,
        "areaname": "\u53F0\u4E2D\u5E02",
        "center": ""
    }, {
        "id": 331000,
        "areaname": "\u53F0\u5DDE\u5E02",
        "center": "121.428599,28.661378"
    }, {
        "id": 140100,
        "areaname": "\u592A\u539F\u5E02",
        "center": "112.549248,37.857014"
    }, {
        "id": 370900,
        "areaname": "\u6CF0\u5B89\u5E02",
        "center": "117.129063,36.194968"
    }, {
        "id": 321200,
        "areaname": "\u6CF0\u5DDE\u5E02",
        "center": "119.915176,32.484882"
    }, {
        "id": 130200,
        "areaname": "\u5510\u5C71\u5E02",
        "center": "118.175393,39.635113"
    }, {
        "id": 120100,
        "areaname": "\u5929\u6D25\u5E02",
        "center": "117.190182,39.125596"
    }, {
        "id": 620500,
        "areaname": "\u5929\u6C34\u5E02",
        "center": "105.724998,34.578529"
    }, {
        "id": 211200,
        "areaname": "\u94C1\u5CAD\u5E02",
        "center": "123.844279,42.290585"
    }, {
        "id": 220500,
        "areaname": "\u901A\u5316\u5E02",
        "center": "125.936501,41.721177"
    }, {
        "id": 150500,
        "areaname": "\u901A\u8FBD\u5E02",
        "center": "122.263119,43.617429"
    }, {
        "id": 610200,
        "areaname": "\u94DC\u5DDD\u5E02",
        "center": "108.979608,34.916582"
    }, {
        "id": 340700,
        "areaname": "\u94DC\u9675\u5E02",
        "center": "117.816576,30.929935"
    }, {
        "id": 520600,
        "areaname": "\u94DC\u4EC1\u5E02",
        "center": "109.191555,27.718346"
    }, {
        "id": 652100,
        "areaname": "\u5410\u9C81\u756A\u5730\u533A",
        "center": "89.186661,42.941938"
    }]
}, {
    "code": "U",
    "citylist": []
}, {
    "code": "V",
    "citylist": []
}, {
    "code": "W",
    "citylist": [{
        "id": 371000,
        "areaname": "\u5A01\u6D77\u5E02",
        "center": "122.116394,37.509691"
    }, {
        "id": 370700,
        "areaname": "\u6F4D\u574A\u5E02",
        "center": "119.107078,36.70925"
    }, {
        "id": 610500,
        "areaname": "\u6E2D\u5357\u5E02",
        "center": "109.502882,34.499381"
    }, {
        "id": 330300,
        "areaname": "\u6E29\u5DDE\u5E02",
        "center": "120.672111,28.000575"
    }, {
        "id": 532600,
        "areaname": "\u6587\u5C71\u58EE\u65CF\u82D7\u65CF\u81EA\u6CBB\u5DDE",
        "center": "104.24401,23.36951"
    }, {
        "id": 150300,
        "areaname": "\u4E4C\u6D77\u5E02",
        "center": "106.825563,39.673734"
    }, {
        "id": 150900,
        "areaname": "\u4E4C\u5170\u5BDF\u5E03\u5E02",
        "center": "113.114543,41.034126"
    }, {
        "id": 650100,
        "areaname": "\u4E4C\u9C81\u6728\u9F50\u5E02",
        "center": "87.617733,43.792818"
    }, {
        "id": 820300,
        "areaname": "\u65E0\u5802\u533A\u5212\u5206\u533A\u57DF",
        "center": ""
    }, {
        "id": 320200,
        "areaname": "\u65E0\u9521\u5E02",
        "center": "120.301663,31.574729"
    }, {
        "id": 640300,
        "areaname": "\u5434\u5FE0\u5E02",
        "center": "106.199409,37.986165"
    }, {
        "id": 340200,
        "areaname": "\u829C\u6E56\u5E02",
        "center": "118.376451,31.326319"
    }, {
        "id": 450400,
        "areaname": "\u68A7\u5DDE\u5E02",
        "center": "111.297604,23.474803"
    }, {
        "id": 420100,
        "areaname": "\u6B66\u6C49\u5E02",
        "center": "114.298572,30.584355"
    }, {
        "id": 620600,
        "areaname": "\u6B66\u5A01\u5E02",
        "center": "102.634697,37.929996"
    }]
}, {
    "code": "X",
    "citylist": [{
        "id": 610100,
        "areaname": "\u897F\u5B89\u5E02",
        "center": "108.948024,34.263161"
    }, {
        "id": 630100,
        "areaname": "\u897F\u5B81\u5E02",
        "center": "101.778916,36.623178"
    }, {
        "id": 532800,
        "areaname": "\u897F\u53CC\u7248\u7EB3\u50A3\u65CF\u81EA\u6CBB\u5DDE",
        "center": "100.797941,22.001724"
    }, {
        "id": 152500,
        "areaname": "\u9521\u6797\u90ED\u52D2\u76DF",
        "center": "116.090996,43.944018"
    }, {
        "id": 350200,
        "areaname": "\u53A6\u95E8\u5E02",
        "center": "118.11022,24.490474"
    }, {
        "id": 421200,
        "areaname": "\u54B8\u5B81\u5E02",
        "center": "114.328963,29.832798"
    }, {
        "id": 610400,
        "areaname": "\u54B8\u9633\u5E02",
        "center": "108.705117,34.333439"
    }, {
        "id": 810100,
        "areaname": "\u9999\u6E2F\u5C9B",
        "center": ""
    }, {
        "id": 430300,
        "areaname": "\u6E58\u6F6D\u5E02",
        "center": "112.944052,27.82973"
    }, {
        "id": 433100,
        "areaname": "\u6E58\u897F\u571F\u5BB6\u65CF\u82D7\u65CF\u81EA\u6CBB\u5DDE",
        "center": "109.739735,28.314296"
    }, {
        "id": 420600,
        "areaname": "\u8944\u9633\u5E02",
        "center": "112.144146,32.042426"
    }, {
        "id": 420900,
        "areaname": "\u5B5D\u611F\u5E02",
        "center": "113.926655,30.926423"
    }, {
        "id": 140900,
        "areaname": "\u5FFB\u5DDE\u5E02",
        "center": "112.733538,38.41769"
    }, {
        "id": 810300,
        "areaname": "\u65B0\u754C",
        "center": ""
    }, {
        "id": 410700,
        "areaname": "\u65B0\u4E61\u5E02",
        "center": "113.883991,35.302616"
    }, {
        "id": 360500,
        "areaname": "\u65B0\u4F59\u5E02",
        "center": "114.930835,27.810834"
    }, {
        "id": 710600,
        "areaname": "\u65B0\u7AF9\u5E02",
        "center": ""
    }, {
        "id": 411500,
        "areaname": "\u4FE1\u9633\u5E02",
        "center": "114.075031,32.123274"
    }, {
        "id": 152200,
        "areaname": "\u5174\u5B89\u76DF",
        "center": "122.070317,46.076268"
    }, {
        "id": 130500,
        "areaname": "\u90A2\u53F0\u5E02",
        "center": "114.508851,37.0682"
    }, {
        "id": 320300,
        "areaname": "\u5F90\u5DDE\u5E02",
        "center": "117.184811,34.261792"
    }, {
        "id": 411000,
        "areaname": "\u8BB8\u660C\u5E02",
        "center": "113.826063,34.022956"
    }, {
        "id": 341800,
        "areaname": "\u5BA3\u57CE\u5E02",
        "center": "118.757995,30.945667"
    }]
}, {
    "code": "Y",
    "citylist": [{
        "id": 511800,
        "areaname": "\u96C5\u5B89\u5E02",
        "center": "103.001033,29.987722"
    }, {
        "id": 370600,
        "areaname": "\u70DF\u53F0\u5E02",
        "center": "121.391382,37.539297"
    }, {
        "id": 610600,
        "areaname": "\u5EF6\u5B89\u5E02",
        "center": "109.49081,36.596537"
    }, {
        "id": 222400,
        "areaname": "\u5EF6\u8FB9\u671D\u9C9C\u65CF\u81EA\u6CBB\u5DDE",
        "center": "129.513228,42.904823"
    }, {
        "id": 320900,
        "areaname": "\u76D0\u57CE\u5E02",
        "center": "120.139998,33.377631"
    }, {
        "id": 321000,
        "areaname": "\u626C\u5DDE\u5E02",
        "center": "119.421003,32.393159"
    }, {
        "id": 441700,
        "areaname": "\u9633\u6C5F\u5E02",
        "center": "111.975107,21.859222"
    }, {
        "id": 140300,
        "areaname": "\u9633\u6CC9\u5E02",
        "center": "113.583285,37.861188"
    }, {
        "id": 230700,
        "areaname": "\u4F0A\u6625\u5E02",
        "center": "128.899396,47.724775"
    }, {
        "id": 654000,
        "areaname": "\u4F0A\u7281\u54C8\u8428\u514B\u81EA\u6CBB\u5DDE",
        "center": "81.317946,43.92186"
    }, {
        "id": 511500,
        "areaname": "\u5B9C\u5BBE\u5E02",
        "center": "104.630825,28.760189"
    }, {
        "id": 420500,
        "areaname": "\u5B9C\u660C\u5E02",
        "center": "111.290843,30.702636"
    }, {
        "id": 360900,
        "areaname": "\u5B9C\u6625\u5E02",
        "center": "114.391136,27.8043"
    }, {
        "id": 430900,
        "areaname": "\u76CA\u9633\u5E02",
        "center": "112.355042,28.570066"
    }, {
        "id": 640100,
        "areaname": "\u94F6\u5DDD\u5E02",
        "center": "106.278179,38.46637"
    }, {
        "id": 360600,
        "areaname": "\u9E70\u6F6D\u5E02",
        "center": "117.033838,28.238638"
    }, {
        "id": 210800,
        "areaname": "\u8425\u53E3\u5E02",
        "center": "122.235151,40.667432"
    }, {
        "id": 431100,
        "areaname": "\u6C38\u5DDE\u5E02",
        "center": "111.608019,26.434516"
    }, {
        "id": 610800,
        "areaname": "\u6986\u6797\u5E02",
        "center": "109.741193,38.290162"
    }, {
        "id": 450900,
        "areaname": "\u7389\u6797\u5E02",
        "center": "110.154393,22.63136"
    }, {
        "id": 632700,
        "areaname": "\u7389\u6811\u85CF\u65CF\u81EA\u6CBB\u5DDE",
        "center": "97.008522,33.004049"
    }, {
        "id": 530400,
        "areaname": "\u7389\u6EAA\u5E02",
        "center": "102.543907,24.350461"
    }, {
        "id": 430600,
        "areaname": "\u5CB3\u9633\u5E02",
        "center": "113.132855,29.37029"
    }, {
        "id": 445300,
        "areaname": "\u4E91\u6D6E\u5E02",
        "center": "112.044439,22.929801"
    }, {
        "id": 140800,
        "areaname": "\u8FD0\u57CE\u5E02",
        "center": "111.003957,35.022778"
    }]
}, {
    "code": "Z",
    "citylist": [{
        "id": 370400,
        "areaname": "\u67A3\u5E84\u5E02",
        "center": "117.557964,34.856424"
    }, {
        "id": 440800,
        "areaname": "\u6E5B\u6C5F\u5E02",
        "center": "110.364977,21.274898"
    }, {
        "id": 430800,
        "areaname": "\u5F20\u5BB6\u754C\u5E02",
        "center": "110.479921,29.127401"
    }, {
        "id": 130700,
        "areaname": "\u5F20\u5BB6\u53E3\u5E02",
        "center": "114.884091,40.811901"
    }, {
        "id": 620700,
        "areaname": "\u5F20\u6396\u5E02",
        "center": "100.455472,38.932897"
    }, {
        "id": 350600,
        "areaname": "\u6F33\u5DDE\u5E02",
        "center": "117.661801,24.510897"
    }, {
        "id": 530600,
        "areaname": "\u662D\u901A\u5E02",
        "center": "103.717216,27.336999"
    }, {
        "id": 441200,
        "areaname": "\u8087\u5E86\u5E02",
        "center": "112.472529,23.051546"
    }, {
        "id": 321100,
        "areaname": "\u9547\u6C5F\u5E02",
        "center": "119.452753,32.204402"
    }, {
        "id": 410100,
        "areaname": "\u90D1\u5DDE\u5E02",
        "center": "113.665412,34.757975"
    }, {
        "id": 442000,
        "areaname": "\u4E2D\u5C71\u5E02",
        "center": "113.308,22.6803"
    }, {
        "id": 640500,
        "areaname": "\u4E2D\u536B\u5E02",
        "center": "105.189568,37.514951"
    }, {
        "id": 500100,
        "areaname": "\u91CD\u5E86\u5E02",
        "center": "106.504962,29.533155"
    }, {
        "id": 330900,
        "areaname": "\u821F\u5C71\u5E02",
        "center": "122.106863,30.016028"
    }, {
        "id": 411600,
        "areaname": "\u5468\u53E3\u5E02",
        "center": "114.649653,33.620357"
    }, {
        "id": 430200,
        "areaname": "\u682A\u6D32\u5E02",
        "center": "113.151737,27.835806"
    }, {
        "id": 440400,
        "areaname": "\u73E0\u6D77\u5E02",
        "center": "113.553986,22.224979"
    }, {
        "id": 411700,
        "areaname": "\u9A7B\u9A6C\u5E97\u5E02",
        "center": "114.024736,32.980169"
    }, {
        "id": 512000,
        "areaname": "\u8D44\u9633\u5E02",
        "center": "104.641917,30.122211"
    }, {
        "id": 370300,
        "areaname": "\u6DC4\u535A\u5E02",
        "center": "118.047648,36.814939"
    }, {
        "id": 510300,
        "areaname": "\u81EA\u8D21\u5E02",
        "center": "104.773447,29.352765"
    }, {
        "id": 659000,
        "areaname": "\u81EA\u6CBB\u533A\u76F4\u8F96",
        "center": "87.617733,43.792818"
    }, {
        "id": 520300,
        "areaname": "\u9075\u4E49\u5E02",
        "center": "106.937265,27.706626"
    }]
}]