export const cityjsonhot = [{
    "id": 410500,
    "areaname": "\u5B89\u9633\u5E02",
    "center": "114.352482,36.103442"
}, {
    "id": 110100,
    "areaname": "\u5317\u4EAC\u5E02",
    "center": "116.405285,39.904989"
}, {
    "id": 510100,
    "areaname": "\u6210\u90FD\u5E02",
    "center": "104.065735,30.659462"
}, {
    "id": 330100,
    "areaname": "\u676D\u5DDE\u5E02",
    "center": "120.153576,30.287459"
}, {
    "id": 410600,
    "areaname": "\u9E64\u58C1\u5E02",
    "center": "114.295444,35.748236"
}, {
    "id": 370100,
    "areaname": "\u6D4E\u5357\u5E02",
    "center": "117.000923,36.675807"
}, {
    "id": 419001,
    "areaname": "\u6D4E\u6E90\u5E02",
    "center": "112.509,35.0243"
}, {
    "id": 410800,
    "areaname": "\u7126\u4F5C\u5E02",
    "center": "113.238266,35.23904"
}, {
    "id": 410200,
    "areaname": "\u5F00\u5C01\u5E02",
    "center": "114.341447,34.797049"
}, {
    "id": 410300,
    "areaname": "\u6D1B\u9633\u5E02",
    "center": "112.434468,34.663041"
}, {
    "id": 411100,
    "areaname": "\u6F2F\u6CB3\u5E02",
    "center": "114.026405,33.575855"
}, {
    "id": 320100,
    "areaname": "\u5357\u4EAC\u5E02",
    "center": "118.767413,32.041544"
}, {
    "id": 411300,
    "areaname": "\u5357\u9633\u5E02",
    "center": "112.540918,32.999082"
}, {
    "id": 410400,
    "areaname": "\u5E73\u9876\u5C71\u5E02",
    "center": "113.307718,33.735241"
}, {
    "id": 410900,
    "areaname": "\u6FEE\u9633\u5E02",
    "center": "115.041299,35.768234"
}, {
    "id": 370200,
    "areaname": "\u9752\u5C9B\u5E02",
    "center": "120.355173,36.082982"
}, {
    "id": 411200,
    "areaname": "\u4E09\u95E8\u5CE1\u5E02",
    "center": "111.194099,34.777338"
}, {
    "id": 411400,
    "areaname": "\u5546\u4E18\u5E02",
    "center": "115.650497,34.437054"
}, {
    "id": 310100,
    "areaname": "\u4E0A\u6D77\u5E02",
    "center": "121.472644,31.231706"
}, {
    "id": 130100,
    "areaname": "\u77F3\u5BB6\u5E84\u5E02",
    "center": "114.502461,38.045474"
}, {
    "id": 320500,
    "areaname": "\u82CF\u5DDE\u5E02",
    "center": "120.619585,31.299379"
}, {
    "id": 120100,
    "areaname": "\u5929\u6D25\u5E02",
    "center": "117.190182,39.125596"
}, {
    "id": 370700,
    "areaname": "\u6F4D\u574A\u5E02",
    "center": "119.107078,36.70925"
}, {
    "id": 320200,
    "areaname": "\u65E0\u9521\u5E02",
    "center": "120.301663,31.574729"
}, {
    "id": 610100,
    "areaname": "\u897F\u5B89\u5E02",
    "center": "108.948024,34.263161"
}, {
    "id": 410700,
    "areaname": "\u65B0\u4E61\u5E02",
    "center": "113.883991,35.302616"
}, {
    "id": 411500,
    "areaname": "\u4FE1\u9633\u5E02",
    "center": "114.075031,32.123274"
}, {
    "id": 411000,
    "areaname": "\u8BB8\u660C\u5E02",
    "center": "113.826063,34.022956"
}, {
    "id": 410100,
    "areaname": "\u90D1\u5DDE\u5E02",
    "center": "113.665412,34.757975"
}, {
    "id": 500100,
    "areaname": "\u91CD\u5E86\u5E02",
    "center": "106.504962,29.533155"
}, {
    "id": 411600,
    "areaname": "\u5468\u53E3\u5E02",
    "center": "114.649653,33.620357"
}, {
    "id": 411700,
    "areaname": "\u9A7B\u9A6C\u5E97\u5E02",
    "center": "114.024736,32.980169"
}]